
    <mat-card-header>
        <mat-card-title>How To Complete</mat-card-title>
    </mat-card-header>
    <mat-card-content>
            <br>1. Select the currency you would like to use.
            <br>2. Select the appropriate Industry Sector.​​
            <br>3. Enter your companies Revenue for as many years as you can, the application will
                automatically average out your entries.
            <br>4. The application calculates your company valuation range.
<p></p>
       <h2>Sector Multiple</h2>
       The sector multiple provides an average EV for that sector that is then applied to your company's valuation calculation.
<p></p>
       <h2>​Enterprise Value (EV)</h2>
       EV is the market value of debt + equity, or the gross purchase price.​
<p></p>
       <h2>Valuation Output</h2>
       Valuation is calculated based on an average of 3 year historical financial performance and, projected current year, for both revenue and operating profit multiples per sector.
<p></p>
       Variances are likely due to differing financial structures in the industry but also imply range of value. For further details, please contact us or request a valuation report.
    </mat-card-content>
    <mat-card-actions align="center">

        <button mat-button (click)="closeDialog()">Close</button>

    </mat-card-actions>
