import { Injectable } from '@angular/core';
import { ThrottlingConstants } from '@azure/msal-common/dist/utils/Constants';
import { Subject } from 'rxjs';
import { ICompanyValuationBasic } from 'src/app/shared/CompanyValuation/ICompanyValuationBasic';
import { IQuestionnaireForm } from 'src/app/shared/CorporateFinanceTool/IQuestionnaireForm';
import { environment } from 'src/environments/environment';
import { ICorporateFinanceForm } from '../../../../src/app/shared/CorporateFinanceTool/ICorporateFinanceForm';
import { ICorporateClientSetup } from './contact-details/contact-details-form/ICorporateClientSetup';
 

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public CorporateFinanceForm : ICorporateFinanceForm;

  public Registered = new Subject<ICorporateFinanceForm>();

  constructor() { 

    this.CorporateFinanceForm = {} as ICorporateFinanceForm;

  }

  public IsSubmitted(): boolean
  {

    return this.CorporateFinanceForm.CorporateFinanceFormId > 0 ? true : false;
  }

  public  getQuestionnaireForm() :  IQuestionnaireForm 
  {
    
    return  this.CorporateFinanceForm.QuestionnaireForm;
  }
  

  public  getCompanyValuationBasic() :  ICompanyValuationBasic 
  {
    
    return  this.CorporateFinanceForm.CompanyValuationBasic;
  }

  public ClientRegistered()
  {

    this.Registered.next(this.CorporateFinanceForm);
    
  }

  public setQuestionnaireForm(questionnaireForm : IQuestionnaireForm)
  {
    this.CorporateFinanceForm.QuestionnaireForm = questionnaireForm;
  }

  public setCompanyValuationBasic(companyValuationBasic : ICompanyValuationBasic)
  {
    this.CorporateFinanceForm.CompanyValuationBasic = companyValuationBasic;
  }


  public setTotalScore(totalScore : number)
  {
    this.CorporateFinanceForm.TotalScore = totalScore;
  }

  // Email : ['',[Validators.required, Validators.email]],
  // FirstName​ : ['', Validators.required],
  // LastName​ : ['', Validators.required],
  // PhoneNumber ​: [''],
  // AccountantFirm : [''],
  // CompanyName : [''],

  public setContactDetails(email: string, firstName: string, lastName: string,
     phoneNumber : string, accountantFirm : string, companyName : string, expectedValuation : string )
  {
    this.CorporateFinanceForm.Email = email;
    this.CorporateFinanceForm.FirstName = firstName;
    this.CorporateFinanceForm.LastName = lastName;
    this.CorporateFinanceForm.PhoneNumber = phoneNumber;
    this.CorporateFinanceForm.AccountantFirm = accountantFirm;
    this.CorporateFinanceForm.CompanyName = companyName;
    this.CorporateFinanceForm.CorporateFinanceFormName = "LeadGenForm";
    this.CorporateFinanceForm.ApplicationId = parseInt(environment.applicationId);
    this.CorporateFinanceForm.ExpectedValuation = expectedValuation;

  }


  public SelectedCorporateClientSetup: ICorporateClientSetup;

}
