import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-how-to-complete',
  templateUrl: './how-to-complete.component.html',
  styleUrls: ['./how-to-complete.component.scss']
})
export class HowToCompleteComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<HowToCompleteComponent>,) { }

  ngOnInit(): void {
  }

  public closeDialog()
  {
    this.dialogRef.close();
  }

}
