import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LearnHowToSellComponent } from './learn-how-to-sell/learn-how-to-sell.component';

const routes: Routes = [

  { path: 'What-Is-My-Company-Value', loadChildren: () => import('./valuation-tool/valuation-tool.module').then(m => m.ValuationToolModule) },
  { path: "Am-I-Ready-To-Sell", loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule) },
  { path: "Learn-How-To-Sell",  loadChildren : () => import('./learn-how-to-sell/lean-ho-to-sell.module').then(m=> m.LeanHoToSellModule) },
  { path: 'ContactDetailsForm', loadChildren : () => import('./contact-details/contact-details.module').then(m=> m.ContactDetailsModule) },
  { path: '',   loadChildren: () => import('./valuation-tool/valuation-tool.module').then(m => m.ValuationToolModule) },
  { path: '**',   redirectTo: 'What-Is-My-Company-Value', pathMatch: 'full' },
  
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
