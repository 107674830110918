import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from 'src/app/shared/material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ValuationToolModule } from './valuation-tool/valuation-tool.module';
import { LearnHowToSellComponent } from './learn-how-to-sell/learn-how-to-sell.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PlatformCoreModule } from 'src/app/core/platform-core.module';
import { HttpClientModule } from '@angular/common/http';
import { InsightsModule } from 'src/app/core/insights/insights.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    ReactiveFormsModule,
    PlatformCoreModule,
    HttpClientModule,
    InsightsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
