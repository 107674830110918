 <div class="page-container">
  <mat-toolbar color="primary">
 
  <mat-toolbar-row *ngIf="!(this.isHandset$  | async)">
    <a  href="https://{{this.SelectedCorporateClientSetup.Homepage}}" (click)="clickButton('HomePage')"  target="_blank" >
      <img
        src="{{this.SelectedCorporateClientSetup.UrlLogo}}"
        style="max-width:58px; width:auto; max-height:58px; height:auto;" />
    </a>
    <span style="margin-right: 5px;"></span>
    <span>To Calculate Valuation Enter Your Company Details Below</span>
    <span class="example-spacer"></span>
    <a    href='{{this.SelectedCorporateClientSetup.CalendarLink}}' target="_blank" (click)=" clickButton('MainScheduleACall')" >
      Schedule a Call
    </a>
    

    <button mat-icon-button  type="button" matTooltip="How To Input Data Please Click on Help Icon or Contact Us" aria-label="Example icon button with a home icon" (click)="openHowToCompleteDialog(); clickButton('MainHelp')">
      <mat-icon>help</mat-icon></button>

      <!-- <button mat-mini-fab color="accent" matTooltip="How To Input Data Please Click on Help Icon or Contact Us" aria-label="Example icon button with a home icon" (click)="openHowToCompleteDialog()">
        <mat-icon>help</mat-icon>
      </button> -->
  </mat-toolbar-row>

  <mat-toolbar-row *ngIf="this.isHandset$  | async">
    <a  href="https://{{this.SelectedCorporateClientSetup.Homepage}}"  (click)=" clickButton('MobileHomePage')" target="_blank" >
      <img
        src="{{this.SelectedCorporateClientSetup.UrlLogo}}"
        style="max-width:58px; width:auto; max-height:58px; height:auto;" />
    </a>
    <span style="margin-right: 5px;"></span>
    <span>Company Valuation </span>
  
    <a  href='{{this.SelectedCorporateClientSetup.CalendarLink}}' (click)=" clickButton('MainMobileScheduleACall')" target="_blank"  >
      <mat-icon>calendar_add_on</mat-icon>
    </a>
    
  </mat-toolbar-row>

  </mat-toolbar>

  <!-- <nav mat-tab-nav-bar mat-stretch-tab class="mat-elevation-z4" [color]="'accent'" [tabPanel]="tabPanel">
    <a mat-tab-link *ngFor="let link of links;index as i;" (click)="activateTab(i)" [active]="activeLink == link[0]"
      [routerLink]="['./'+ link[0] +'']">{{link[1]}}</a>
  </nav>
  <mat-tab-nav-panel #tabPanel>  -->
    <router-outlet></router-outlet>

  <!-- </mat-tab-nav-panel> -->

  <div *ngIf="this.SelectedCorporateClientSetup" style="margin-top: 1px; padding: 8px" fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z1">
    <div fxLayout="row" fxLayout.lt-md="column"  fxLayoutAlign="start center" fxLayoutGap="20px">

      <span class="v-align-middle">
        <h3>To Learn More Contact:</h3>
      </span>
      <div>
        <a  href="https://{{this.SelectedCorporateClientSetup.Homepage}}"  target="_blank" >
          <img
            src="{{this.SelectedCorporateClientSetup.UrlLogo}}"
            style="max-width:300px; width:auto; max-height:100px; height:auto;" />
        </a>
      </div>
      <!-- 
      <a mat-stroked-button color="primary" href="https://thefe.group/" target="_blank">Visit Our Website</a> -->

      <div fxLayout="column" fxLayoutAlign="start stretch">
        <div fxFlex="1 1 auto">
          <mat-icon class="v-align-middle">mail</mat-icon> <a href="mailto:{{this.SelectedCorporateClientSetup.Email}}" (click)="clickButton('HomePageEmail')">{{this.SelectedCorporateClientSetup.Email}}</a>
        </div>
         <div fxFlex="1 1 auto">
          <mat-icon class="v-align-middle">call</mat-icon> <a class="v-align-middle" href='tel:{{this.SelectedCorporateClientSetup.PhoneContact}}' (click)="clickButton('HomePagePhone')">{{this.SelectedCorporateClientSetup.PhoneContact}}</a>
        </div>
        <div fxFlex="1 1 auto">
          <mat-icon class="v-align-middle">home</mat-icon> <a class="v-align-middle" href="https://{{this.SelectedCorporateClientSetup.Homepage}}" (click)="clickButton('HomePage')"
            target="_blank" >{{this.SelectedCorporateClientSetup.Homepage}}</a>
        </div>

      </div>

    </div>
  </div>

</div>