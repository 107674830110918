import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Insights } from 'src/app/core/insights/insights.service';
import { environment } from 'src/environments/environment';
import { ICorporateClientSetup } from './contact-details/contact-details-form/ICorporateClientSetup';
import { SessionService } from './session.service';
import { HowToCompleteComponent } from './valuation-tool/how-to-complete/how-to-complete.component';


type Links = [url: string, label: string];
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CorporateFinanceTool';

  public applicationId: number;
  public SelectedCorporateClientSetup: ICorporateClientSetup;

  constructor(private router: Router, private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private sessionService: SessionService,
    private dialog: MatDialog,
    private insights: Insights) {

    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {

        if (event.url == '/What-Is-My-Company-Value') this.activateTab(0);
        if (event.url == '/Learn-How-To-Sell') this.activateTab(1);
        if (event.url == '/Am-I-Ready-To-Sell') this.activateTab(2);
      });

    this.applicationId = parseInt(environment.applicationId);
    this.setByInstance();
  }

  links: Links[] = [
    ['What-Is-My-Company-Value', '1. What is My Company Value?'],
    //  ['Learn-How-To-Sell', '2. Learn How to Sell'],
    ['Am-I-Ready-To-Sell', '3. Am I Ready to Sell?'],
  ];

  activeLink = this.links[0][0];

  activateTab(tabIndex: number) {
    this.activeLink = this.links[tabIndex][0];
  }

  ngOnInit() {
    //this.setUpAnalytics();

    this.isHandset$.subscribe((result: boolean) => {

      if (result) {


        this.links = [
          ['What-Is-My-Company-Value', 'Company Value'],
          //  ['Learn-How-To-Sell', 'Learn to Sell'],
          ['Am-I-Ready-To-Sell', 'Ready to Sell'],
        ];

      }
      else {
        this.links = [
          ['What-Is-My-Company-Value', 'My Company Value'],
          //     ['Learn-How-To-Sell', 'Learn How to Sell'],
          ['Am-I-Ready-To-Sell', 'Am I Ready to Sell?'],
        ];

      }

    });
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'GTM-PVH56GJ',
          {
            page_path: event.urlAfterRedirects
          }
        );
      });
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
    .pipe(
      map(result => result.matches),
      shareReplay()
    )


  setByInstance() {
    this.SelectedCorporateClientSetup = null;

    switch (this.applicationId) {

      case 222:

        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://tagtax.co.uk/wp-content/uploads/2021/06/tagtax.png";
        this.SelectedCorporateClientSetup.Email = "helpline@tagtax.co.uk ";
        this.SelectedCorporateClientSetup.Homepage = "tagtax.co.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://tagtax.co.uk/contact/';
        this.SelectedCorporateClientSetup.PhoneContact = "01228 276606";

        break;

      case 221:
        //this.calendarLink = 'https://calendly.com/rohan-fegroup';
        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://madealplatformstorage.blob.core.windows.net/public-madealplatform/TheFeGroupLogo.png";
        this.SelectedCorporateClientSetup.Email = "finance@theFE.group";
        this.SelectedCorporateClientSetup.Homepage = "thefe.group";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://calendly.com/rohan-fegroup';
        this.SelectedCorporateClientSetup.PhoneContact = "02038130195";

        break;

      case 254:


        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://jmjaccountancy.uk/wp-content/themes/jmj/images/jmj-logo-header.png";
        this.SelectedCorporateClientSetup.Email = "info@jmjaccountancy.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "jmjaccountancy.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'jmjaccountancy.uk/contact-us/';
        this.SelectedCorporateClientSetup.PhoneContact = "0845 094 5125";
        break;

      case 223:
        //  this.calendarLink = "https://www.fairmontcorporate.com/selling-a-company-ad#sellingpageform";
        break;
      case 283:
        // this.calendarLink = "https://www.kortica.co.uk";

        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://www.kortica.co.uk/assets/images/image24.png?v=d8b73798";
        this.SelectedCorporateClientSetup.Email = "www.kortica.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "www.kortica.co.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://www.kortica.co.uk/#who-we-are';
        this.SelectedCorporateClientSetup.PhoneContact = "";
        break;

      case 133:
        // this.calendarLink = 'https://www.madealplatform.com/meetings/id/sales';

        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;
        this.SelectedCorporateClientSetup.UrlLogo = "https://madealplatformstorage.blob.core.windows.net/public-madealplatform/WhiteLabelLogo.png";
        this.SelectedCorporateClientSetup.Email = "info@madealplatform.com";
        this.SelectedCorporateClientSetup.Homepage = "www.madealplatform.com";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://www.madealplatform.com/meetings/id/sales';
        this.SelectedCorporateClientSetup.PhoneContact = "+44 0800 640 4231";

        break;

      case 23:

        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "../../assets/m&a_deal_platform_logo.png";
        this.SelectedCorporateClientSetup.Email = "info@madealplatform.com";
        this.SelectedCorporateClientSetup.Homepage = "www.madealplatform.com";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://www.madealplatform.com/meetings/id/sales';
        this.SelectedCorporateClientSetup.PhoneContact = "+44 0800 640 4231";

        break;

      case 304:


        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://madealplatformstorage.blob.core.windows.net/public-madealplatform/scruttonbland.PNG";
        this.SelectedCorporateClientSetup.Email = "hello@scruttonbland.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "www.scruttonbland.co.uk/";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://www.scruttonbland.co.uk/contact-us';
        this.SelectedCorporateClientSetup.PhoneContact = "0330 058 6559";


        break;

      case 204:


        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://www.larking-gowen.co.uk/media/3964/website-master-logo.png";
        this.SelectedCorporateClientSetup.Email = "norwich@larking-gowen.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "www.larking-gowen.co.uk/";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://www.larking-gowen.co.uk/contact-us/';
        this.SelectedCorporateClientSetup.PhoneContact = "01603 667800";

        break;


      case 619:


        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://madealplatformstorage.blob.core.windows.net/public-madealplatform/blackscorporate.png";
        this.SelectedCorporateClientSetup.Email = "abacus@businesstransfergroup.com";
        this.SelectedCorporateClientSetup.Homepage = "www.blackscorporate.com";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://www.blackscorporate.com/contact';
        this.SelectedCorporateClientSetup.PhoneContact = "0333 370 0000";

        break;



      case 48:


        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://images.squarespace-cdn.com/content/v1/5dc17e48c298f37108879bd8/d314940b-8cdc-4df4-a8f2-0320e3796b49/Screenshot+2022-07-28+014434.png?format=1500w";
        this.SelectedCorporateClientSetup.Email = "nabeel@stratomconsulting.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "www.stratomconsulting.co.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://www.stratomconsulting.co.uk/join-the-revolution';
        this.SelectedCorporateClientSetup.PhoneContact = "07944915201";

        break;

      case 290:


        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://www.hub109.co.uk/wp-content/uploads/2022/04/cropped-hub109-logo-copy-01-1-1.jpg";
        this.SelectedCorporateClientSetup.Email = "info@hub109.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "www.hub109.co.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://calendar.google.com/calendar/u/4?cid=aHViMTA5bHRkQGdtYWlsLmNvbQ';
        this.SelectedCorporateClientSetup.PhoneContact = "0121 405 4405";

        break;


      case 314:


        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://madealplatformstorage.blob.core.windows.net/public-madealplatform/propel.PNG";
        this.SelectedCorporateClientSetup.Email = "info@propelcapital.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "www.propelcapital.co.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://www.propelcapital.co.uk/contact-us';
        this.SelectedCorporateClientSetup.PhoneContact = "+44 (0) 79 19902 897";

        break;

      case 378:

        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://madealplatformstorage.blob.core.windows.net/public-madealplatform/balckmarble.png";
        this.SelectedCorporateClientSetup.Email = "Zaman@black-marble.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "www.black-marble.co.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://www.black-marble.co.uk/#ContactBoxMain';
        this.SelectedCorporateClientSetup.PhoneContact = "+44 7957535304";

        break;


      case 107:

        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://madealplatformstorage.blob.core.windows.net/public-madealplatform/powerAccountants.PNG";
        this.SelectedCorporateClientSetup.Email = "hello@power-accountants.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "www.power-accountants.co.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://calendly.com/adam-poweraccountants';
        this.SelectedCorporateClientSetup.PhoneContact = "01244 722342";

        break;

      case 251:

        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://madealplatformstorage.blob.core.windows.net/public-madealplatform/VA-BLACK.png";
        this.SelectedCorporateClientSetup.Email = "stewart@valueadvisers.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "valueadvisers.co.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'http://valueadvisers.co.uk';
        this.SelectedCorporateClientSetup.PhoneContact = "";

        break;

      case 357:

        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://ecovis.co/en/wp-content/uploads/2021/02/cropped-Logo-Header.png";
        this.SelectedCorporateClientSetup.Email = "lggomez@ecovis.co";
        this.SelectedCorporateClientSetup.Homepage = "ecovis.co";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://ecovis.co/en/contact-us';
        this.SelectedCorporateClientSetup.PhoneContact = "+57 1 7436380";
        break;

      case 448:

        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;

        this.SelectedCorporateClientSetup.UrlLogo = "https://madealplatformstorage.blob.core.windows.net/public-madealplatform/mergerscorp.jpg";
        this.SelectedCorporateClientSetup.Email = "info@mergerscorp.com";
        this.SelectedCorporateClientSetup.Homepage = "www.mergerscorp.com";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://calendly.com/mergerscorp/15min?embed_domain=www.mergerscorp.com&embed_type=PopupText';
        this.SelectedCorporateClientSetup.PhoneContact = "+1 212 210 1940";
        break;

      case 436:

        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;
        this.SelectedCorporateClientSetup.UrlLogo = "https://smallbusinessalliance.org.uk/wp-content/uploads/2022/07/logo.png";
        this.SelectedCorporateClientSetup.Email = "sales@smallbusinessalliance.org.uk";
        this.SelectedCorporateClientSetup.Homepage = "smallbusinessalliance.org.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://smallbusinessalliance.org.uk/contact-us';
        this.SelectedCorporateClientSetup.PhoneContact = "03300 568888";

        break;

      case 449:
        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;
        this.SelectedCorporateClientSetup.UrlLogo = "https://inverse.fund/wp-content/uploads/2023/03/cropped-WEBSITE-LOGO-WHITE.png";
        this.SelectedCorporateClientSetup.Email = "ivan@inverse.fund";
        this.SelectedCorporateClientSetup.Homepage = "inverse.fund";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://calendly.com/inverse-funding/30min';
        this.SelectedCorporateClientSetup.PhoneContact = "+34 685 63 30 17";

        break;
    
      case 211:
         this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;
         this.SelectedCorporateClientSetup.UrlLogo = "https://uploads-ssl.webflow.com/623a1ec107af774c5a689b7d/62462575d36977a17417cc2f_flinder%20logo-full%20colour%20B.png";
         this.SelectedCorporateClientSetup.Email = "";
         this.SelectedCorporateClientSetup.Homepage = "www.flinder.co";
         this.SelectedCorporateClientSetup.CalendarLink = 'https://www.flinder.co/contact-us';
         this.SelectedCorporateClientSetup.PhoneContact = "+44 (0) 208 064 2898";
         
      break;

      case 211:
          this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;
          this.SelectedCorporateClientSetup.UrlLogo = "https://uploads-ssl.webflow.com/623a1ec107af774c5a689b7d/62462575d36977a17417cc2f_flinder%20logo-full%20colour%20B.png";
          this.SelectedCorporateClientSetup.Email = "";
          this.SelectedCorporateClientSetup.Homepage = "www.flinder.co";
          this.SelectedCorporateClientSetup.CalendarLink = 'https://www.flinder.co/contact-us';
          this.SelectedCorporateClientSetup.PhoneContact = "+44 (0) 208 064 2898";
          
       break;

       case 486:
        this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;
        this.SelectedCorporateClientSetup.UrlLogo = "https://madealplatformstorage.blob.core.windows.net/public-madealplatform/tweedbusiness_logo.png";
        this.SelectedCorporateClientSetup.Email = "paul.rowley@tweedbusiness.co.uk";
        this.SelectedCorporateClientSetup.Homepage = "www.tweedbusiness.co.uk";
        this.SelectedCorporateClientSetup.CalendarLink = 'https://calendly.com/paul-rowley-1';
        this.SelectedCorporateClientSetup.PhoneContact = "0131 510 8625";

        break;

        case 493:
          this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;
          this.SelectedCorporateClientSetup.UrlLogo = "https://www.doddaccountants.co.uk/media/3ntl3fzk/logo.svg";
          this.SelectedCorporateClientSetup.Email = "stuart.bell@doddaccountants.co.uk";
          this.SelectedCorporateClientSetup.Homepage = "www.doddaccountants.co.uk";
          this.SelectedCorporateClientSetup.CalendarLink = 'https://www.doddaccountants.co.uk/enquiry';
          this.SelectedCorporateClientSetup.PhoneContact = "01768864466";
          break;
        case 838:
          this.SelectedCorporateClientSetup = {} as ICorporateClientSetup;
          this.SelectedCorporateClientSetup.UrlLogo = "https://www.jmbaccounting.co.uk/wp-content/uploads/2022/04/JMB-Colour-logo.png";
          this.SelectedCorporateClientSetup.Email = "jbuckett@jmbaccounting.co.uk";
          this.SelectedCorporateClientSetup.Homepage = "www.jmbaccounting.co.uk/";
          this.SelectedCorporateClientSetup.CalendarLink = 'https://calendly.com/jmbaccounting';
          this.SelectedCorporateClientSetup.PhoneContact = "01428 727313";

        break;
        

    }

    this.sessionService.SelectedCorporateClientSetup = this.SelectedCorporateClientSetup;
    this.insights.trackEvent("LeadGenLoaded");

  }

  public openHowToCompleteDialog() {
    const howToCompleteDialog = this.dialog.open(HowToCompleteComponent,
      this.breakpointObserver.isMatched(Breakpoints.Handset) ?
        {
          //data: companyValuationBasic,
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
        } :
        {
          //data: companyValuationBasic,
          position: { top: '20px' },
          // height: '80%',
          // maxHeight: '80vh',
          width: '700px',
        }
    );
  }


  clickButton(buttonId: string): void {

    this.insights.trackButtonTrace('ButtonClicked', buttonId, null, null);
  }





}
